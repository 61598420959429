// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:a2b40f91-ea65-4c5d-8406-6baaf5b55cb5",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_EThyccT1n",
    "aws_user_pools_web_client_id": "70hj92nrel9ctpspklu6j6r19t",
    "oauth": {}
};


export default awsmobile;
